import React, { ReactNode } from 'react';
import Header from '../components/Header';
import './BaseLayout.css';
import FooterSection from '../components/Footer';
import MSAlert from '../core-components/MSAlert';
const isTestSite = false;

interface BaseLayoutProps {
    children: ReactNode;
}

const BaseLayout: React.FC<BaseLayoutProps> = ({ children }) => {
    return (
        <div className="base-layout">
            <Header />
            {isTestSite ? <MSAlert message={'This is a test site only'} /> : null}
            <div className="content">
                {children}
            </div>
            <FooterSection />
        </div>
    );
};

export default BaseLayout;