import React from 'react';
import './ProductPage.css';
import TextField from '../components/TextField';
import { landingHomeData } from '../config/pagesData';
import useScreenSize from '../hooks/useScreenSize';
import { 
    PAGE_TITLE_FONT_SIZE_MOBILE, 
    PAGE_TITLE_FONT_SIZE_DESKTOP, 
    PAGE_DESCRIPTION_FONT_SIZE_DESKTOP, 
    PAGE_DESCRIPTION_FONT_SIZE_MOBILE, 
    PRODUCT_PAGE_ITEM_DESCRIPTION_MOBILE, 
    PRODUCT_PAGE_ITEM_TITLE_MOBILE, 
    PRODUCT_PAGE_ITEM_DESCRIPTION_DESKTOP, 
    PRODUCT_PAGE_ITEM_TITLE_DESKTOP 
} from '../config/constants';

const ProductPage: React.FC = () => {
    const { header, items } = landingHomeData.productPage;
    const isMobile = useScreenSize();

    return (
        <div className="product-section" id="product">
            <div className="product-header">
                <TextField
                    text={header.title}
                    size={isMobile ? PAGE_TITLE_FONT_SIZE_MOBILE : PAGE_TITLE_FONT_SIZE_DESKTOP}
                    weight="bold"
                    className="header-title"
                />
                <TextField
                    text={header.description}
                    size={isMobile ? PAGE_DESCRIPTION_FONT_SIZE_MOBILE : PAGE_DESCRIPTION_FONT_SIZE_DESKTOP}
                    style={{ color: '#909090', marginBottom: '20px' }}
                    className="header-description"
                />
            </div>
            <div className="product-grid">
                {items.map((item, index) => (
                    <div key={index} className={`product-item ${index % 2 !== 0 ? 'reverse' : ''}`}>
                        <img src={item.imageUrl} alt={item.title} className="product-image" />
                        <div className="product-text">
                            <TextField
                                text={item.title}
                                size={isMobile ? PRODUCT_PAGE_ITEM_TITLE_MOBILE : PRODUCT_PAGE_ITEM_TITLE_DESKTOP}
                                weight="bold"
                                className="product-item-title"
                                style={{ color: '#e9002f' }}
                            />
                            <TextField
                                text={item.description}
                                size={isMobile ? PRODUCT_PAGE_ITEM_DESCRIPTION_MOBILE : PRODUCT_PAGE_ITEM_DESCRIPTION_DESKTOP}
                                className="product-item-description"
                                style={{ color: '#ffff' }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProductPage;
