import { FaShieldAlt, FaDollarSign, FaChartLine, FaUserAlt, FaGlobe, FaBalanceScale, FaCogs, FaMoneyBillWave } from 'react-icons/fa';

const landingHomeData= {
    introPage: {
        announcementBar: {
            text: 'Announcement text here',
        },
        header: {
            title: {
                main: 'Empowering Digital Creators',
                sub: 'Transforming Music Industry',
            },
            description: 'Revolutionizing Music Rights and Royalties. Empowering creators with transparent, seamless management, ensuring fair, timely payments. Simplify your music distribution journey and maximize earnings with cutting-edge solutions.',
        },
        ctaButtons: [
            {
                text: 'Join as Artist',
                primary: true,
                theme: 'primary'
            },
            {
                text: 'Partner with Us',
                theme: 'primary',
                variant: 'outlined' as 'outlined',
            },
        ],
    },
    featuresPage: {
        header: {
            title: "Mixstream: Where Innovation Meets Empowerment",
            description: "Mixstream is not just a platform; it's a movement. We've harnessed the latest technologies to create a suite of powerful features designed to revolutionize the music industry. From blockchain-secured rights management to intelligent data analytics, Mixstream empowers artists like never before."
        },
        features: [
            {
                icon: <FaShieldAlt size={40} />,
                title: "Blockchain Security",
                description: "Leveraging blockchain's immutability and transparency to safeguard your music rights. Your intellectual property remains secure."
            },
            {
                icon: <FaDollarSign size={40} />,
                title: "Fast Payouts",
                description: "Automating royalty distributions with smart contracts to eliminate intermediaries, ensuring artists receive payments swiftly and accurately."
            },
            {
                icon: <FaChartLine size={40} />,
                title: "Data Analytics",
                description: "Advanced analytics provide deep insights into your music's performance, enabling data-driven decisions and optimizing your success."
            },
            {
                icon: <FaUserAlt size={40} />,
                title: "Artist-Centric Design",
                description: "An intuitive interface and streamlined workflows make managing your music rights effortless."
            },
            {
                icon: <FaGlobe size={40} />,
                title: "Global Distribution",
                description: "Seamlessly reach audiences worldwide across major platforms."
            },
            {
                icon: <FaBalanceScale size={40} />,
                title: "Royalty Tracking",
                description: "Real-time tracking of royalty earnings and distributions, ensuring transparency and accuracy."
            },
            {
                icon: <FaCogs size={40} />,
                title: "Flexible Licensing",
                description: "Easily manage and customize licensing agreements for different regions and platforms."
            },
            {
                icon: <FaMoneyBillWave size={40} />,
                title: "Monetization Options",
                description: "Explore diverse monetization strategies, from streaming to merchandising."
            }
        ]
    },
    teamPage: {
        header: {
            title: 'Meet Our Team',
            description: 'Our team of dedicated professionals is here to make Mixstream a success. Meet the minds behind the platform.',
        },
        teamMembers: [
            {
                id: 1,
                name: 'Sami Ahmad Khan',
                role: 'Founder',
                imageUrl: '/sami_khan.jpg',
                details: 'Sami is a software engineer, with passion for music.',
            },
            {
                id: 2,
                name: 'Dylan Parent',
                role: 'Co-Founder',
                imageUrl: '/dylan_parent.jpeg',
                details: 'Passion for technology and building cool things. currently @ Google.'
            },
            {
                id: 3,
                name: 'Nate Chovenac',
                role: 'Core Contributor',
                imageUrl: '/nate_chovenac.jpeg',
                details: 'Lorem Ipsum',
            },
            {
                id: 4,
                name: 'Toshiro Heckett',
                role: 'Core Contributor',
                imageUrl: '/toshiro_heckett.jpeg',
                details: 'Lorem Ipsum',
            },
        ],
    },
    pricingPage: {
        header: {
            title: 'Choose Your Plan',
            description: 'Mixstream offers flexible pricing plans to suit your needs. Whether you\'re an independent artist or a large enterprise, we have a plan for you.',
        },
        plans: [
            {
                title: 'Free',
                price: '$0/month',
                features: ['Basic Rights Management', 'Standard Analytics', 'Email Support'],
                button: {
                    text: 'Get Started',
                },
            },
            {
                title: 'Pro',
                price: '$29/month',
                features: ['Advanced Rights Management', 'Detailed Analytics', 'Priority Email Support', 'Customizable Dashboards'],
                button: {
                    text: 'Get Started',
                },
                isHighlighted: true,
            },
            {
                title: 'Enterprise',
                price: 'Contact Us',
                features: ['Full Rights Management Suite', 'Comprehensive Analytics', 'Dedicated Account Manager', 'Custom Integrations'],
                button: {
                    text: 'Get Started',
                },
            },
        ],
    },
    productPage: {
        header: {
            title: "The Future of Music Creation & Distribution",
            description: "Embrace cutting-edge tools designed for modern creators. From transparent royalty distribution to comprehensive rights management, our platform empowers you to focus on what you do best – making music.",
        },
        items: [
            {
                title: "Transparent Royalty Distribution",
                description: "Say goodbye to opaque royalty statements and delayed payments. Mixstream ensures artists receive their fair share of royalties promptly and transparently. Our blockchain-powered platform provides a clear, auditable record of every transaction, giving you the visibility you deserve.",
                imageUrl: "/transparency.png"
            },
            {
                title: "Empowering Data Insights",
                description: "Knowledge is power, and Mixstream puts that power in your hands. Gain deep insights into your music's performance with advanced analytics. Track listener demographics, streaming trends, and revenue sources to make informed decisions.",
                imageUrl: "/data-insights.png"
            },
            {
                title: "Comprehensive Rights Management",
                description: "Easily manage and monitor your music rights in one place. Our intuitive dashboard allows you to oversee your entire music catalog, track usage, and enforce your rights effectively.",
                imageUrl: '/rights-management.png',
            },
            {
                title: "Earn Royalties in FIAT, and Faster!",
                description: "Experience the convenience of faster royalty payments directly to your bank account in FIAT currency. Enjoy the benefits of blockchain technology without the need for cryptocurrency wallets, keeping your financial setup as simple as it is today.",
                imageUrl: '/royalties-faster.png',
            },
            {
                title: "Automated Licensing Solutions",
                description: "Streamline the licensing process with automated solutions. Create, manage, and distribute licenses quickly and efficiently, ensuring compliance and maximizing revenue.",
                imageUrl: '/automated-licensing.png',
            },
            {
                title: "Global Distribution Network",
                description: "Expand your reach with Mixstream’s global distribution network. Distribute your music to major streaming platforms worldwide and connect with a global audience.",
                imageUrl: '/distribution-network.png',
            },
            {
                title: "Efficient Royalty Management",
                description: "Accurately calculate and distribute royalties with our automated system. Ensure timely and fair payments to all stakeholders.",
                imageUrl: '/royalties-management.png',
            },
            {
                title: "Collaborative Tools",
                description: "Enhance collaboration with tools designed for music creators. Share projects, manage contributions, and communicate seamlessly with team members.",
                imageUrl: '/collaborative-tools.png',
            },
        ]
    }
};

const artistsJoinPageData = {
    headerPage: {
        header: {
            title: {
                main: 'Calling All Artists',
                sub: 'Join Mixstream and Revolutionize Your Music Journey',
            },
            description: "Become a part of the future of music rights management and distribution. Sign up now to avail exclusive benefits.",
        },
    }
}

export {
    landingHomeData,
    artistsJoinPageData
}
