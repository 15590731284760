import React, { useState } from 'react';
import './StayUpdatedPage.css';
import MSButton from '../core-components/MSButton';
import TextField from '../components/TextField';
import useScreenSize from '../hooks/useScreenSize';
import { useHubspotSubscription } from '../hooks/useHubspot'; // or wherever your subscription hook is
import {
  STAY_UPDATED_DESCRIPTION_FONT_SIZE_DESKTOP,
  STAY_UPDATED_DESCRIPTION_FONT_SIZE_MOBILE,
  STAY_UPDATED_TITLE_FONT_SIZE_DESKTOP,
  STAY_UPDATED_TITLE_FONT_SIZE_MOBILE,
} from '../config/constants';

const StayUpdatedPage: React.FC = () => {
  const isMobile = useScreenSize();
  const [email, setEmail] = useState('');
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');
  const { subscribeEmail, isLoading } = useHubspotSubscription();

  const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setSubmitStatus('error');
      return;
    }
    try {
      await subscribeEmail(email); 
      setSubmitStatus('success');
      setEmail('');
    } catch (error) {
      setSubmitStatus('error');
    }
  };

  const getStatusMessage = () => {
    switch (submitStatus) {
      case 'success':
        return <div className="status-message success">Thanks for subscribing!</div>;
      case 'error':
        return <div className="status-message error">Please enter a valid email address</div>;
      default:
        return null;
    }
  };

  return (
    <div className="stay-updated-section">
      <div className="stay-updated-header">
        <TextField
          text="Join the Movement for Artistic Freedom"
          size={isMobile ? STAY_UPDATED_TITLE_FONT_SIZE_MOBILE : STAY_UPDATED_TITLE_FONT_SIZE_DESKTOP}
          weight="bold"
        />
        <TextField
          text="Subscribe to receive the latest updates and news about Mixstream"
          size={isMobile ? STAY_UPDATED_DESCRIPTION_FONT_SIZE_MOBILE : STAY_UPDATED_DESCRIPTION_FONT_SIZE_DESKTOP}
          style={{ color: '#909090', marginBottom: '40px' }}
        />
      </div>
      <div className="stay-updated-form">
        <input
          type="email"
          className={`email-input ${submitStatus === 'error' ? 'error' : ''}`}
          placeholder="Enter your email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (submitStatus !== 'idle') setSubmitStatus('idle');
          }}
          onClick={(e) => {
            if (!isLoading) {
               handleSubmit();
            }
          }}
        />
        <MSButton
          text={isLoading ? 'Subscribing...' : 'Subscribe'}
          variant="contained"
          className="subscribe-button"
          onClick={handleSubmit}
          enabled={!isLoading}
        />
      </div>
      {getStatusMessage()}
    </div>
  );
};

export default StayUpdatedPage;