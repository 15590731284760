import React from 'react';
import TextField from '../components/TextField';
import { landingHomeData } from '../config/pagesData';
import './FeaturesPage.css';
import useScreenSize from '../hooks/useScreenSize';
import { FEATURE_ITEM_DESCRIPTION_FONT_SIZE_DESKTOP, FEATURE_ITEM_DESCRIPTION_FONT_SIZE_MOBILE, FEATURE_ITEM_TITLE_FONT_SIZE_DESKTOP, FEATURE_ITEM_TITLE_FONT_SIZE_MOBILE, PAGE_DESCRIPTION_FONT_SIZE_DESKTOP, PAGE_DESCRIPTION_FONT_SIZE_MOBILE, PAGE_TITLE_FONT_SIZE_DESKTOP, PAGE_TITLE_FONT_SIZE_MOBILE } from '../config/constants';

const FeaturesPage: React.FC = () => {
    const { header, features } = landingHomeData.featuresPage;
    const isMobile = useScreenSize();

    return (
        <div className="features-section">
            <div className="features-header">
                <TextField
                    text={header.title}
                    size={
                        isMobile ? PAGE_TITLE_FONT_SIZE_MOBILE : PAGE_TITLE_FONT_SIZE_DESKTOP
                    }
                    weight="bold"
                    className="header-title"
                />
                <TextField
                    text={header.description}
                    size={isMobile ? PAGE_DESCRIPTION_FONT_SIZE_MOBILE : PAGE_DESCRIPTION_FONT_SIZE_DESKTOP}
                    style={{ color: '#909090', marginBottom: '20px' }}
                    className="header-description"
                />
            </div>
            <div className="features-grid">
                {features.map((feature, index) => (
                    <div key={index} className="feature-item">
                        <div className="feature-card">
                            <div className="feature-card-front">
                                <div className="feature-icon">
                                    {feature.icon}
                                </div>
                                <TextField
                                    text={feature.title}
                                    size={isMobile ? FEATURE_ITEM_TITLE_FONT_SIZE_MOBILE : FEATURE_ITEM_TITLE_FONT_SIZE_DESKTOP}
                                    weight="bold"
                                    style={{ color: '#e9002f' }}
                                />
                            </div>
                            <div className="feature-card-back">
                                <TextField
                                    text={feature.description}
                                    size={isMobile ? FEATURE_ITEM_DESCRIPTION_FONT_SIZE_MOBILE : FEATURE_ITEM_DESCRIPTION_FONT_SIZE_DESKTOP}
                                    style={{ color: '#0a0a0a', padding: '20px' }}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeaturesPage;
