export const LOGO_TEXT_SIZE_MOBILE =  26;
export const LOGO_TEXT_SIZE_DESKTOP = 30;
export const LOGO_ICON_SIZE_MOBILE = 32;
export const LOGO_ICON_SIZE_DESKTOP = 36;

export const MAIN_INTRO_PAGE_TITLE_SIZE_DESKTOP = 70;
export const MAIN_INTRO_PAGE_TITLE_SIZE_MOBILE = 30;
export const MAIN_INTRO_PAGE_DESCRIPTION_SIZE_DESKTOP = 22;
export const MAIN_INTRO_PAGE_DESCRIPTION_SIZE_MOBILE = 14;
export const MAIN_INTRO_PAGE_TEXT_SIZE_DESKTOP = 18;
export const MAIN_INTRO_PAGE_TEXT_SIZE_MOBILE = 14;

export const PAGE_TITLE_FONT_SIZE_DESKTOP = 40;
export const PAGE_TITLE_FONT_SIZE_MOBILE = 24;
export const PAGE_DESCRIPTION_FONT_SIZE_DESKTOP = 20;
export const PAGE_DESCRIPTION_FONT_SIZE_MOBILE = 14;
export const PRODUCT_PAGE_ITEM_TITLE_DESKTOP = 30;
export const PRODUCT_PAGE_ITEM_TITLE_MOBILE = 18;
export const PRODUCT_PAGE_ITEM_DESCRIPTION_DESKTOP = 16;
export const PRODUCT_PAGE_ITEM_DESCRIPTION_MOBILE = 12;
export const FEATURE_ITEM_TITLE_FONT_SIZE_DESKTOP = 24;
export const FEATURE_ITEM_TITLE_FONT_SIZE_MOBILE = 18;
export const FEATURE_ITEM_DESCRIPTION_FONT_SIZE_DESKTOP = 14;
export const FEATURE_ITEM_DESCRIPTION_FONT_SIZE_MOBILE= 12;
export const STAY_UPDATED_TITLE_FONT_SIZE_DESKTOP = 55;
export const STAY_UPDATED_TITLE_FONT_SIZE_MOBILE = 30;
export const STAY_UPDATED_DESCRIPTION_FONT_SIZE_DESKTOP = 20;
export const STAY_UPDATED_DESCRIPTION_FONT_SIZE_MOBILE= 16;

// export const PAGE_TITLE_SIZE = 

