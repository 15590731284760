import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import Logo from '../core-components/MSLogo';
import TextField from './TextField';
import MSButton from '../core-components/MSButton';
import { useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Header: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [menuOpen, setMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };


    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let headerClasses = 'header';
    

    return (
        <header className={headerClasses}>
            <div className="header-container">
                <div className="header-left-group">
                    <div className="ms-logo" onClick={handleLogoClick}>
                        <Logo />
                    </div>
                </div>
                {!isMobile && (
                    <>
                        <div className="header-middle-group">
                            <nav className="nav-center">
                                <ul>
                                    <li>
                                        <Link to="/" >
                                            <TextField text="About" weight='bold' size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/product" >
                                            <TextField text="Product" weight='bold' size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/features" >
                                            <TextField text="Features" weight='bold' size={16} />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" >
                                            <TextField text="Contact" weight='bold' size={16} />
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="header-right-group">
                            <Link to="early-access" className='no-underline'>
                                <MSButton
                                    text="Sign-up for early access"
                                    variant='contained'
                                    theme='primary'
                                />
                            </Link>
                        </div>
                    </>
                )}
                {isMobile && (
                    <div className="hamburger-menu">
                        {menuOpen ? (
                            <FaTimes className="hamburger-icon" onClick={toggleMenu} />
                        ) : (
                            <FaBars className="hamburger-icon" onClick={toggleMenu} />
                        )}
                        {menuOpen && (
                            <div className={`dropdown-menu ${menuOpen ? 'open' : ''}`}>
                                <Link className="menu-item" to="/about" onClick={toggleMenu}>
                                    About
                                </Link>
                                <Link className="menu-item" to="/product" onClick={toggleMenu}>
                                    Product
                                </Link>
                                <Link className="menu-item" to="/features" onClick={toggleMenu}>
                                    Features
                                </Link>
                                <Link className="menu-item" to="/contact" onClick={toggleMenu}>
                                    Contact
                                </Link>
                                
                            </div>
                        )}
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
