import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IntroPage.css';
import TextField from '../components/TextField';
import AnnouncementBar from '../components/AnnouncementBar';
import CTAButton from '../components/CTAButton';
import { landingHomeData } from '../config/pagesData';
import { FaPlayCircle } from 'react-icons/fa';
import MSButton from '../core-components/MSButton';
import useScreenSize from '../hooks/useScreenSize';
import Modal from '../components/Modal'; // Ensure correct path to Modal component
import {
    MAIN_INTRO_PAGE_DESCRIPTION_SIZE_DESKTOP,
    MAIN_INTRO_PAGE_DESCRIPTION_SIZE_MOBILE,
    MAIN_INTRO_PAGE_TEXT_SIZE_DESKTOP,
    MAIN_INTRO_PAGE_TEXT_SIZE_MOBILE,
    MAIN_INTRO_PAGE_TITLE_SIZE_DESKTOP,
    MAIN_INTRO_PAGE_TITLE_SIZE_MOBILE
} from '../config/constants';

const IntroPage: React.FC = () => {
    const { title, description } = landingHomeData.introPage.header;
    const navigate = useNavigate();
    const isMobile = useScreenSize();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { header, teamMembers } = landingHomeData.teamPage;
   

    const handleWatchDemoClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="ms-landing-home-intro-container">
            <AnnouncementBar />
            <div className="intro-title">
                <TextField
                    text={title.main}
                    size={isMobile ? MAIN_INTRO_PAGE_TITLE_SIZE_MOBILE : MAIN_INTRO_PAGE_TITLE_SIZE_DESKTOP}
                    weight='bold'
                    style={{ color: '#e9002f' }}
                />
                <TextField
                    text={title.sub}
                    size={isMobile ? MAIN_INTRO_PAGE_TITLE_SIZE_MOBILE : MAIN_INTRO_PAGE_TITLE_SIZE_DESKTOP}
                    weight="bold"
                />
            </div>
            <div className="intro-description">
                <TextField
                    text={description}
                    size={isMobile ? MAIN_INTRO_PAGE_DESCRIPTION_SIZE_MOBILE : MAIN_INTRO_PAGE_DESCRIPTION_SIZE_DESKTOP}
                    style={{ color: '#909090', marginBottom: '20px' }}
                />
            </div>
            <div className="cta-watch-demo">
                <CTAButton
                    text="Watch Demo"
                    variant="outlined"
                    theme="secondary"
                    icon={<FaPlayCircle />}
                    onClick={handleWatchDemoClick}
                />
            </div>
           
            <Modal isOpen={isModalOpen} onClose={closeModal} />

            <div className="team-section">
            <div className="team-header">
                <TextField text={header.title} size={40} weight="bold" />
                <TextField
                    text={header.description}
                    size={20}
                    style={{ color: '#909090', marginBottom: '20px' }}
                />
            </div>
            <div className="team-grid">
                {teamMembers.map((member) => (
                    <div className="team-item" key={member.id}>
                        <div className="team-card">
                            <div className="team-card-front">
                                <img src={member.imageUrl} alt={member.name} className="team-image" />
                                <div className="team-role">
                                    <strong>{member.name}</strong>
                                    <p>{member.role}</p>
                                </div>
                            </div>
                            <div className="team-card-back">
                                <p>{member.details}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>

        </div>
    );
};

export default IntroPage;
